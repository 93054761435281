import { useState } from 'react';
import { daysBetweenTimestamps } from '../formatting';

const getInstallPromptLastSeenAt = (promptName) => localStorage.getItem(promptName);

const setInstallPromptSeenToday = (promptName) => {
  const today = new Date().toISOString();
  localStorage.setItem(promptName, today);
};

// FIXME This still prompts every single time on iOS
function getUserShouldBePromptedToInstall(promptName, daysToWaitBeforePromptingAgain) {
  const lastPrompt = getInstallPromptLastSeenAt(promptName);
  // FIXME I don't know that we even want this at all... it should be fairly often like IG and those since it's pretty
  //  important to get them to install it
  const daysSinceLastPrompt = daysBetweenTimestamps(new Date().toISOString(), lastPrompt);
  // alert(`Testing: ${daysSinceLastPrompt} days since last install prompt`);
  return isNaN(daysSinceLastPrompt) || daysSinceLastPrompt >= daysToWaitBeforePromptingAgain;
}

const useInstallPrompt = (promptName, daysToWaitBeforePromptingAgain = 10) => {
  const [userShouldBePromptedToInstall, setUserShouldBePromptedToInstall] = useState(
    getUserShouldBePromptedToInstall(promptName, daysToWaitBeforePromptingAgain)
  );

  const handleUserSeeingInstallPrompt = () => {
    setUserShouldBePromptedToInstall(false);
    setInstallPromptSeenToday(promptName);
  };

  return [userShouldBePromptedToInstall, handleUserSeeingInstallPrompt];
};
export default useInstallPrompt;
