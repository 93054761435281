import axios from 'axios';
import { config } from '../../settings';
import wpApi from '../wpApi';
import Post from '../../models/Post';
import { SearchStore } from '../../stores';

let cancelToken;
export const search = (query, { cigars = true, venues = true, brands = true, products = true, users = true, groups = true, articles = true }) => {
  // Check if there are any previous pending requests
  if (typeof cancelToken !== typeof undefined) {
    cancelToken.cancel('Operation canceled due to new request.');
  }

  // Save the cancel token for the current request
  cancelToken = axios.CancelToken.source();

  if (cigars) {
    SearchStore.update((s) => { s.cigarsLoading = true; });
    axios.get(`${config.apiEndPoint}/cigars/search`, {
      params: {
        q: query,
        limit: 20, // TODO Limit this even more? Expand more?
      },
      cancelToken: cancelToken.token,
    }).then((response) => {
      const c = response.data || [];
      SearchStore.update((s) => {
        s.cigars = c;
        s.cigarsLoading = false;
      });
    }).catch((err) => {
      console.error(err);
      SearchStore.update((s) => { s.cigarsLoading = false; });
    });
  }

  if (venues) {
    axios.get(`${config.apiEndPoint}/venues/search`, {
      params: {
        q: query,
        limit: 20, // TODO Limit this even more?
      },
      cancelToken: cancelToken.token,
    }).then((response) => {
      const v = response.data || [];
      SearchStore.update((s) => { s.venues = v; });
    }).catch((err) => {
      console.error(err);
      SearchStore.update((s) => { s.venuesLoading = false; });
    });
  }

  if (brands) {
    axios.get(`${config.apiEndPoint}/brands`, {
      params: {
        q: query,
        limit: 20, // TODO Limit this even more?
      },
      cancelToken: cancelToken.token,
    }).then((response) => {
      const v = response.data || [];
      SearchStore.update((s) => { s.brands = v; });
    }).catch((err) => {
      console.error(err);
      SearchStore.update((s) => { s.brandsLoading = false; });
    });
  }

  if (products) {
    axios.get(`${config.shopEndPoint}/products/search`, {
      params: {
        q: query,
        limit: 20, // TODO Limit this even more?
      },
      cancelToken: cancelToken.token,
    }).then((response) => {
      const p = response.data || [];
      SearchStore.update((s) => { s.products = p; });
    }).catch((err) => {
      console.error(err);
      SearchStore.update((s) => { s.productsLoading = false; });
    });
  }

  if (groups) {
    axios.get(`${config.apiEndPoint}/groups/search`, {
      params: {
        q: query,
        limit: 20, // TODO Limit this even more?
      },
      cancelToken: cancelToken.token,
    }).then((response) => {
      const g = response.data || [];
      SearchStore.update((s) => { s.groups = g; });
    }).catch((err) => {
      console.error(err);
      SearchStore.update((s) => { s.groupsLoading = false; });
    });
  }

  if (users) {
    axios.get(`${config.apiEndPoint}/users/search`, {
      params: {
        q: query,
        limit: 20, // TODO Limit this even more?
      },
      cancelToken: cancelToken.token,
    }).then((response) => {
      const u = response.data || [];
      SearchStore.update((s) => { s.users = u; });
    }).catch((err) => {
      console.error(err);
      SearchStore.update((s) => { s.usersLoading = false; });
    });
  }

  if (articles) {
    try {
      const request = wpApi.posts();
      request.search(query);
      const response = request.embed().perPage(config.pagingItem).page(1);
      const posts = response.map((item) => new Post(item));
      SearchStore.update((s) => { s.articles = posts; });
    } catch (error) {
      console.error(error);
      SearchStore.update((s) => { s.articlesLoading = false; });
    }
  }
};
