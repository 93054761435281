// @flow
import axios from 'axios';
import { BusinessStore, CigarStore, GroupStore, ShopStore, VenueStore } from '../../stores';
import { redirectAuth } from '../redirect';

const env = process.env.NODE_ENV || 'development';
const config = require('../../../config/config.json')[env];

export const likeItem = (user, item) => {
  if (user && user.id) {
    axios.post(`${config.apiEndPoint}/users/${user.id}/likes`, item).then((likedItem) => {
      if (item.type === 'session') {
        CigarStore.update((s) => {
          s.sessions.liked.push(likedItem.item_id);
        });
      }
      if (item.type === 'checkin') {
        VenueStore.update((s) => {
          s.checkins.liked.push(likedItem.item_id);
        });
      }
      if (item.type === 'product_review') {
        ShopStore.update((s) => {
          s.products.reviews.liked.push(likedItem.item_id);
        });
      }
      if (item.type === 'group_post') {
        GroupStore.update((s) => {
          s.posts.liked.push(likedItem.item_id);
        });
      }
      if (item.type === 'business_post') {
        BusinessStore.update((s) => {
          s.posts.liked.push(likedItem.item_id);
        });
      }
      if (item.type === 'event') {
        BusinessStore.update((s) => {
          s.events.liked.push(likedItem.item_id);
        });
      }
      if (item.type === 'blog_article') {
        BusinessStore.update((s) => {
          s.blogs.articles.liked.push(likedItem.item_id);
        });
      }
      if (item.type === 'podcast_episode') {
        BusinessStore.update((s) => {
          s.podcasts.episodes.liked.push(likedItem.item_id);
        });
      }
      // TODO Update for remaining types...
    });
  } else {
    // TODO Can we trigger the like when it returns?
    redirectAuth(`${window.location.href}?action=like&itemId=${item.item_id}&type=${item.type}`, false);
  }
};

export const unlikeItem = (user, item) => {
  if (user && user.id) {
    console.log('Unliking item:');
    console.log(item);
    axios.delete(`${config.apiEndPoint}/users/${user.id}/likes`, {
      data: item,
    }).then(() => {
      if (item.type === 'session') {
        CigarStore.update((s) => {
          s.sessions.liked.splice(s.sessions.liked.indexOf(item.item_id), 1);
        });
      }
      if (item.type === 'checkin') {
        VenueStore.update((s) => {
          s.checkins.liked.splice(s.checkins.liked.indexOf(item.item_id), 1);
        });
      }
      if (item.type === 'product_review') {
        ShopStore.update((s) => {
          s.products.liked.splice(s.products.liked.indexOf(item.item_id), 1);
        });
      }
      if (item.type === 'group_post') {
        GroupStore.update((s) => {
          s.posts.liked.splice(s.posts.liked.indexOf(item.item_id), 1);
        });
      }
      if (item.type === 'business_post') {
        BusinessStore.update((s) => {
          s.posts.liked.splice(s.posts.liked.indexOf(item.item_id), 1);
        });
      }
      if (item.type === 'event') {
        BusinessStore.update((s) => {
          s.events.liked.splice(s.events.liked.indexOf(item.item_id), 1);
        });
      }
      if (item.type === 'blog_article') {
        BusinessStore.update((s) => {
          s.blogs.articles.liked.splice(s.blogs.articles.liked.indexOf(item.item_id), 1);
        });
      }
      if (item.type === 'podcast_episode') {
        BusinessStore.update((s) => {
          s.podcasts.episodes.liked.splice(s.podcasts.episodes.liked.indexOf(item.item_id), 1);
        });
      }
      // TODO Update for remaining types...
    });
  } else {
    // TODO Can we trigger the unlike when it returns? FIXME It will probably never happen, since we need a user to know that the item is liked
    redirectAuth(`${window.location.href}?action=unlike&itemId=${item.item_id}&type=${item.type}`, false);
  }
};
