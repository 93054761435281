import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { config } from '../settings';
import { ShopStore, UserStore } from '../stores';
import PushController from './pushController';
import * as Sentry from '@sentry/react';
import { Transducer } from './transducer';

export default {
  get: (cartToken) => new Promise((resolve, reject) => {
    axios.get(`${config.shopEndPoint}/carts/${cartToken}`)
      .then((response) => {
        ShopStore.update((s) => {
          s.cart = response.data;
          s.modified = true;
        });
        Transducer.setPreference('cart', response.data);
        resolve(response.data);
      }).catch((err) => {
        reject(err);
      });
  }),
  sync: (userId) => new Promise((resolve, reject) => {
    axios.get(`${config.shopEndPoint}/carts/users/${userId}`)
      .then((response) => {
        ShopStore.update((s) => {
          s.cart = response.data;
          s.modified = true;
        });
        Transducer.setPreference('cart', response.data);
        resolve(response.data);
      }).catch((err) => {
        reject(err);
      });
  }),
  create: (defaults = {}) => new Promise((resolve, reject) => {
    const cart = {
      token: uuidv4(),
      ...defaults,
    };
    const { user } = UserStore.getRawState();
    if (!cart.user && user && user.id) {
      cart.user_id = user.id;
      cart.user = user;
    }
    axios.post(`${config.shopEndPoint}/carts`, cart)
      .then((response) => {
        ShopStore.update((s) => {
          s.cart = response.data;
          s.modified = true;
        });
        Transducer.setPreference('cart', response.data);
        resolve(response.data);
      }).catch((err) => {
        reject(err);
      });
  }),
  update: (cart, abandon = false) => new Promise((resolve, reject) => {
    console.log('Updating cart', cart);
    const { user } = UserStore.getRawState();
    if (!cart.user && user && user.id) {
      ShopStore.update((s) => {
        s.cart.user = user;
        s.cart.user_id = user.id;
      });
      cart.user = user;
      cart.user_id = user.id;
    }
    if (cart.id) {
      // INFO For some reason, this line breaks the checkout - any .update() calls don't finish - likely from useEffect,
      //  so built new object below instead
      // cart.status = 'in_progress';
      axios.put(`${config.shopEndPoint}/carts/${cart.id}`, { ...cart, status: abandon ? 'abandoned' : 'in_progress' })
        .then((response) => {
          console.log('Cart updated on server');
          console.log(response.data);
          const updatedCart = response.data;
          if (updatedCart.shipping_rate && updatedCart.shipping_rate.shipping_method) {
            updatedCart.shipping_rate.name = updatedCart.shipping_rate.shipping_method.name;
          }
          ShopStore.update((s) => {
            s.cart = updatedCart;
            s.modified = false;
          });
          Transducer.setPreference('cart', updatedCart);
          resolve(response.data);
        }).catch((err) => {
          reject(err);
        });
    } else {
      axios.post(`${config.shopEndPoint}/carts`, cart)
        .then((response) => {
          ShopStore.update((s) => {
            s.cart = response.data;
            s.modified = false;
          });
          resolve(response.data);
        }).catch((err) => {
          reject(err);
        });
    }
  }),
  addProduct: (product) => new Promise((resolve) => {
    console.log('Adding new product');
    // FIXME This needs to recognize if a product is already in the cart and simply increase the quantity instead of add another line item
    let { cart } = ShopStore.getRawState();
    if (!cart) {
      cart = {
        token: uuidv4(),
        // TODO Link whatever else we have available
      };
      // FIXME If there is a user available, this needs to also create the cart on the server
    }
    const items = cart.items ? [...cart.items] : [];
    items.push({
      product: {
        ...product,
        id: product.product_id,
      },
      quantity: product.quantity || 1,
      price: product.price,
      subtotal_price: (product.price * (product.quantity || 1)),
      unique_id: uuidv4(),
    });
    let total = 0;
    items.forEach((item) => {
      total += item.subtotal_price;
    });
    console.log('Updated cart line items:');
    console.log(cart);
    ShopStore.update((s) => {
      s.cart = cart;
      s.cart.subtotal_price = total;
      s.cart.items = items;
      s.modified = true;
    });
    Transducer.setPreference('cart', {
      ...cart,
      subtotal_price: total,
      items,
    });
    try {
      PushController.addCartTags(product, cart);
    } catch (e) {
      Sentry.captureException(e);
    }
    // FIXME This should also update the server
    resolve();
  }),
  updateProduct: (data) => new Promise((resolve, reject) => {
    const { cart } = ShopStore.getRawState();
    console.log('Updating cart:');
    console.log(cart);
    // FIXME This should also update the server
    if (cart) {
      if (cart.items) {
        const items = [...cart.items];
        let i = items.length;
        while (i--) {
          const item = items[i];
          console.log('Updating item:');
          console.log(item);
          if (item.unique_id === data.unique_id) {
            item.quantity = data.quantity;
            item.total_price = (data.price * data.quantity);
            break;
          }
        }
        let total = 0;
        items.forEach((item) => {
          total += item.total_price;
        });
        cart.subtotal_price = total;
        ShopStore.update((s) => {
          s.cart = cart;
          s.cart.items = items;
          s.modified = true;
        });
        Transducer.setPreference('cart', {
          ...cart,
          items,
        });
        PushController.addCartTags(data, cart);
        resolve();
      } else {
        reject('Product not found');
      }
    } else {
      reject('Cart not initialized');
    }
  }),
  removeProduct: (uniqueId) => new Promise((resolve, reject) => {
    console.log(`Removing product with unique ID ${uniqueId}`);
    // TODO Remove the line item (what's the best way to do this in the db - actually delete the record or set active flag to false?)
    const { cart } = ShopStore.getRawState();
    if (cart.items) {
      const items = [...cart.items];
      let i = items.length;
      while (i--) {
        const item = items[i];
        if (item.unique_id === uniqueId) {
          items.splice(i, 1);
          break;
        }
      }
      let total = 0;
      items.forEach((item) => {
        total += item.total_price;
      });
      ShopStore.update((s) => {
        s.cart = cart;
        s.cart.subtotal_price = total;
        s.cart.items = items;
        s.modified = true;
      });
      Transducer.setPreference('cart', {
        ...cart,
        subtotal_price: total,
        items,
      });
      if (cart.items.length === 0) {
        PushController.removeCartTags();
      } else {
        // Switch it to the next product in line
        PushController.addCartTags(cart.items[cart.items.length - 1], cart);
      }
    } else {
      reject('Product not found');
    }
  }),
  applyDiscount: (discountCode) => new Promise((resolve, reject) => {
    const { cart } = ShopStore.getRawState();
    let discounts = [];
    if (cart.discounts && Array.isArray(cart.discounts)) {
      discounts = [...cart.discounts];
    }
    axios.post(`${config.shopEndPoint}/discounts`, { code: discountCode }).then((response) => {
      console.log(response.data);
      if (response.status === 200) {
        discounts.push(response.data);
        ShopStore.update((s) => {
          s.cart = cart;
          s.cart.discounts = discounts;
          s.modified = true;
        });
        Transducer.setPreference('cart', {
          ...cart,
          discounts,
        });
        resolve(cart);
      } else {
        reject(response.data);
      }
    }).catch((err) => {
      reject(err.response.data);
    });
  }),
  removeDiscount: (discountCode) => new Promise((resolve, reject) => {
    const { cart } = ShopStore.getRawState();
    let removeDiscounts = [];
    let discounts = [];
    if (cart.remove_discounts) {
      removeDiscounts = [...cart.remove_discounts];
    }
    if (cart.discounts) {
      discounts = [...cart.discounts];
    }
    // FIXME Not sure this is necessary - we don't really need to make these kind of changes in real-time on the server
    //  Instead, right before the user closes the tab or maybe after a timeout, we need to send the whole cart to the
    //  server and mark it as abandoned for follow-up - should automate it to an extent using a queue or similar
    // axios.delete(`${config.shopEndPoint}/shop/discounts?code=${discountCode}`).then((response) => {
    let i = removeDiscounts.length;
    while (i--) {
      const item = removeDiscounts[i];
      if (item.code === discountCode) {
        removeDiscounts.push(item);
        discounts.splice(i, 1);
        break;
      }
    }
    ShopStore.update((s) => {
      s.cart = cart;
      s.cart.remove_discounts = removeDiscounts;
      s.cart.discounts = discounts;
      s.modified = true;
    });
    Transducer.setPreference('cart', {
      ...cart,
      discounts,
      remove_discounts: removeDiscounts,
    });
    resolve(cart);
    // }).catch((err) => {
    //   reject(err);
    // });
  }),
  fetchShippingRates: () => new Promise((resolve, reject) => {
    const { cart } = ShopStore.getRawState();
    if (cart.shipping_address) {
      axios.get(`${config.shopEndPoint}/shipping/methods?zip=${cart.shipping_address.zip}`).then((response) => {
        // FIXME Not sure why I'm seeing Unhandled Rejection (TypeError): Cannot add property shipping_rates, object is not extensible
        //  but it doesn't seem to affect the code at all
        try {
          ShopStore.update((s) => {
            s.cart = cart;
            s.cart.shipping_rates = response.data;
            s.modified = true;
          });
        } catch (e) {
          console.error(e);
        }
        Transducer.setPreference('cart', {
          ...cart,
          shipping_rates: response.data,
        });
        resolve(cart);
      }).catch((err) => {
        reject(err);
      });
    }
  }),
  // setBillingInformation: (billingAddress) => ShopStore.update((s) => {
  //   s.cart.billing_address = billingAddress;
  //   s.modified = true;
  // }),
  // setShippingInformation: (shippingRate, shippingAddress) => ShopStore.update((s) => {
  //   s.cart.shipping_rate = shippingRate;
  //   s.cart.shipping_price = shippingRate.price;
  //   s.cart.shipping_address = shippingAddress;
  //   s.modified = true;
  // }),
  initializePaymentSession: () => new Promise((resolve, reject) => {
    console.log('Getting payment methods from server:');
    axios.get(`${config.shopEndPoint}/payments/methods`).then((response) => {
      resolve(response.data);
    }).catch((err) => {
      reject(err);
    });
  }),
  fetchTaxRate: (cartId) => new Promise((resolve, reject) => {
    axios.get(`${config.shopEndPoint}/taxes/lookup?cart_id=${cartId}`).then((response) => {
      console.log('Tax rates:');
      console.log(response.data);
      resolve(response.data);
      ShopStore.update((s) => {
        s.cart.tax_rate = response.data; // FIXME This is actually on a line item basis, not the cart
        s.cart.tax_rate_id = response.data.id; // ^^
        s.cart.tax_price = response.data.price;
        s.modified = true;
      });
      Transducer.setPreference('cart', {
        ...ShopStore.getRawState().cart,
        tax_rate: response.data, // FIXME This is actually on a line item basis, not the cart
        tax_rate_id: response.data.id,
        tax_price: response.data.price,
      });
    }).catch((err) => {
      reject(err);
    });
  }),
  processPayment: (data) => new Promise((resolve, reject) => {
    axios.post(`${config.shopEndPoint}/payments/confirm`, data).then((response) => {
      resolve(response.data);
      PushController.removeCartTags();
    }).catch((err) => {
      reject(err);
    });
  }),
  confirm: (data) => new Promise((resolve, reject) => {
    axios.post(`${config.shopEndPoint}/orders`, data).then((response) => {
      resolve(response.data);
      PushController.removeCartTags();
    }).catch((err) => {
      reject(err);
    });
  }),
};
