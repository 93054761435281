import axios from 'axios';
import axiosRetry, { exponentialDelay } from 'axios-retry';
import * as Sentry from '@sentry/react';
import { history } from './browserRouter';
import { UserStore } from '../stores';
import firebaseApp from '../../config/firebase.config';
import { config } from '../settings';
import { Transducer } from './transducer';

export const maybeUpdateCurrentUser = (token) => new Promise((resolve, reject) => {
  const urlParams = new URLSearchParams(window.location.search);
  const userToken = token || urlParams.get('token');
  if (userToken) {
    // TODO See if the user is already logged in first? Should we test to ensure that the 2 users match if they are?
    //  This would sign a user out if they are already logged in... Might be a security/privacy concern...
    axios.defaults.headers.common.Authorization = `Bearer ${userToken}`;
    axios.get(`${config.apiEndPoint}/users/credentials`).then((response) => {
      console.log('User credentials response:');
      console.log(response.data);
      // alert('Got response for credentials!!');
      if (response.data && response.data.tokenId) {
        // alert(JSON.stringify(response.data.tokenId));
        console.log(JSON.stringify(response.data));
        console.log('Got custom token:');
        console.log(response.data.tokenId);
        // alert('Updating Firebase!');
        firebaseApp.auth().signInWithCustomToken(response.data.tokenId).then((updated) => {
          if (updated) {
            console.log('Dispatching authUser...');
            // alert('Updated Firebase!!!');
            firebaseApp.auth().currentUser.getIdToken().then((tokenId) => {
              axios.defaults.headers.common.Authorization = `Bearer ${tokenId}`;
              resolve(response.data.user, tokenId);
            });
          } else {
            console.log('Unable to sign in with custom token');
            Sentry.captureException(new Error('Unable to sign in with custom token'));
            // FIXME Use our custom alert dialog component for this
            alert('There was a problem with authentication. We are working on a fix, but if the problem persists, please contact support.');
          }
        }).catch((err) => {
          // Getting `Failed to execute 'transaction' on 'IDBDatabase': The database connection is closing` when attempting to run from iOS native wrapper
          // alert(err.message);
        });
      }
    });
    if (!window.Capacitor.isNative) {
      urlParams.delete('token');
    }
    history.replace({
      pathname: window.location.pathname,
      search: urlParams.toString(),
    });
  } else {
    console.log('No auth token to use, redirecting...');
    reject();
  }
});

// const checkForQueryAuthToken = (requestConfig) => {
//   const urlParams = new URLSearchParams(window.location.search);
//   const userToken = urlParams.get('token');
//   if (userToken) {
//     console.log('Trying call with token from query param:');
//     console.log(userToken);
//     Transducer.setPreference('boxpressd_token_id', userToken);
//     axios.defaults.headers.common.Authorization = `Bearer ${userToken}`;
//     requestConfig.headers.Authorization = `Bearer ${userToken}`;
//     requestConfig.baseURL = undefined;
//     requestConfig.overrideToken = true;
//     urlParams.delete('token');
//     history.replace({
//       pathname: window.location.pathname,
//       search: urlParams.toString(),
//     });
//   }
//   return requestConfig;
// };

export const updateFirebaseToken = (user) => new Promise((resolve, reject) => {
  console.log('Getting new auth token in axios retry condition...');
  user.getIdToken().then((tokenId) => {
    console.log('Got new auth token in axios retry condition!!');
    axios.defaults.headers.common.Authorization = `Bearer ${tokenId}`;
    Transducer.setPreference('boxpressd_token_id', tokenId);
    resolve(tokenId);
  }).catch((err) => {
    reject(err);
  });
});

const updateAxiosHeaders = (requestConfig, tokenId) => {
  requestConfig.headers.common.Authorization = `Bearer ${tokenId}`;
  requestConfig.headers.Authorization = `Bearer ${tokenId}`;
};

export const initAxiosInterceptor = () => {
  axios.interceptors.request.use(async (requestConfig) => {
    // FIXME I don't know that this step is necessary... try without
    // console.log('Linking auth token in interceptor...');
    // requestConfig = checkForQueryAuthToken(requestConfig);
    // if (requestConfig.overrideToken) {
    //   return requestConfig;
    // }
    console.log('Getting new token...');
    const user = UserStore.getRawState().firebaseUser;
    console.log('Firebase User:');
    console.log(user);
    if (user) {
      console.log('Got user!');
      const updatedToken = await updateFirebaseToken(user);
      if (updatedToken) {
        console.log('Got new firebase token:');
        console.log(updatedToken);
        updateAxiosHeaders(requestConfig, updatedToken);
      }
    }
    return requestConfig;
  }, (error) => Promise.reject(error));
};

const retryStatuses = [403];
export const initAxiosRetry = () => {
  axiosRetry(axios, {
    retries: 5,
    retryDelay: exponentialDelay,
    retryCondition: (e) => {
      // FIXME May want to restrict this to check to see if a user is logged in first
      console.log('=== Checking Retry Request ===', e && e.response && e.response.status);
      return (e && e.response && retryStatuses.indexOf(e.response.status) !== -1);
    },
  });
};
