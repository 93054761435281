import { format, parse, differenceInMilliseconds } from 'date-fns';

/**
 * @param  {String} template
 * @param  {String[]} values
 * @return {String}
 */
function sprintf(template, values) {
  return template.replace(/%s/g, () => values.shift());
}

const oneMin = 60 * 1000;
const oneHr = 60 * oneMin;
const oneDay = 24 * oneHr;
const twoDays = 2 * oneDay;
// const oneWeek = 7 * oneDay;
// const oneMonth = 30 * oneDay;
// const oneYear = 12 * oneMonth;

export const isToday = (date) => {
  const today = new Date();
  return date.getDate() == today.getDate()
    && date.getMonth() == today.getMonth()
    && date.getFullYear() == today.getFullYear();
};

export function renderTimestamp(timestamp) {
  try {
    let formattedDate = 'Just Now';

    // 2019-11-04T01:09:47 - INFO Need to append the timezone as UTC .000Z to all incoming timestamps
    let strFormat = 'yyyy-MM-dd HH:mm:ss.SSSxxx';
    if (timestamp.indexOf('T') !== -1) {
      strFormat = "yyyy-MM-dd'T'HH:mm:ss.SSSxxx";
    }

    let date;
    if (timestamp.indexOf('Z') === -1) {
      // console.log(`${timestamp}.000Z`);
      date = parse(`${timestamp}.000Z`, strFormat, new Date());
    } else {
      date = parse(timestamp, strFormat, new Date());
    }
    const now = new Date();

    // console.log(date.toISOString());
    // console.log(now.toISOString());

    const diff = differenceInMilliseconds(now, date);
    // console.log(`Difference is ${diff}`);

    // Only do intervals up to 2 days before now.
    if (diff > twoDays) {
      // Show formatted timestamp (mmm dd[, yyyy]) <- only show year if not current
      if (format(date, 'yyyy') === format(now, 'yyyy')) {
        formattedDate = format(date, 'MMM d');
      } else {
        formattedDate = format(date, 'MMM d, yyyy');
      }
    } else if (diff >= oneDay) {
      // Show days
      formattedDate = `${Math.round(diff / oneDay)} day ago`;
    } else if (diff >= oneHr) {
      // Show hours
      formattedDate = `${Math.round(diff / oneHr)} hr ago`;
    } else if (diff >= oneMin) {
      // Show mins
      formattedDate = `${Math.round(diff / oneMin)} min ago`;
    }

    return formattedDate;
  } catch (e) {
    console.log(e);
    return '';
  }
}

export function renderEditorTimestamp(timestamp) {
  try {
    let formattedDate = 'Today'; // 'Hoy'

    // 2019-11-04T01:09:47 - INFO Need to append the timezone as UTC .000Z to all incoming timestamps
    let strFormat = 'yyyy-MM-dd HH:mm:ss.SSSxxx';
    if (timestamp.indexOf('T') !== -1) {
      strFormat = "yyyy-MM-dd'T'HH:mm:ss.SSSxxx";
    }

    let date;
    if (timestamp.indexOf('Z') === -1) {
      // console.log(`${timestamp}.000Z`);
      date = parse(`${timestamp}.000Z`, strFormat, new Date());
    } else {
      date = parse(timestamp, strFormat, new Date());
    }
    const now = new Date();

    // console.log(date.toISOString());
    // console.log(now.toISOString());

    const diff = differenceInMilliseconds(now, date);
    // console.log(`Difference is ${diff}`);

    // Only do intervals up to 2 days before now.
    if (diff > twoDays) {
      // Show formatted timestamp (mmm dd[, yyyy]) <- only show year if not current
      if (format(date, 'yyyy') === format(now, 'yyyy')) {
        formattedDate = format(date, 'MMM d');
      } else {
        formattedDate = format(date, 'MMM d, yyyy');
      }
    } else if (diff >= oneDay) {
      // Show days
      formattedDate = `${Math.round(diff / oneDay)} day ago`;
      // FIXME Need to use placeholder %s - Spanish is "Hace 1 día" so "Hace %s dia" or "Hace %s dias" if plural
      // formattedDate = sprintf(messages[language]?.days_passed, Math.round(diff / oneDay));
    } // INFO We stop there to only allow this to say 'Today', '1 day ago', etc

    return formattedDate;
  } catch (e) {
    console.log(e);
    return '';
  }
}

export function renderEventTimestamp(timestamp) {
  try {
    let formattedDate = 'Happening Now';

    // 2019-11-04T01:09:47 - INFO Need to append the timezone as UTC .000Z to all incoming timestamps
    let strFormat = 'yyyy-MM-dd HH:mm:ss.SSSxxx';
    if (timestamp.indexOf('T') !== -1) {
      strFormat = "yyyy-MM-dd'T'HH:mm:ss.SSSxxx";
    }

    let date;
    if (timestamp.indexOf('Z') === -1) {
      // console.log(`${timestamp}.000Z`);
      date = parse(`${timestamp}.000Z`, strFormat, new Date());
    } else {
      date = parse(timestamp, strFormat, new Date());
    }
    const now = new Date();

    // console.log(date.toISOString());
    // console.log(now.toISOString());

    const diff = differenceInMilliseconds(now, date);
    // console.log(`Difference is ${diff}`);

    // TODO Include the time and future dates
    if (diff > twoDays) {
      // Show formatted timestamp (mmm dd[, yyyy]) <- only show year if not current
      if (format(date, 'yyyy') === format(now, 'yyyy')) {
        formattedDate = format(date, 'MMM d');
      } else {
        formattedDate = format(date, 'MMM d, yyyy');
      }
    } else if (diff >= oneDay) {
      // Show days
      formattedDate = `${Math.ceil(diff / oneDay)} day ago`;
    } else if (diff >= oneHr) {
      // Show hours
      formattedDate = `${Math.ceil(diff / oneHr)} hr ago`;
    } else if (diff >= oneMin) {
      // Show mins
      formattedDate = `${Math.ceil(diff / oneMin)} min ago`;
    }

    return formattedDate;
  } catch (e) {
    console.log(e);
    return '';
  }
}

export const formatDate = (timestamp) => {
  let date;
  let strFormat = 'yyyy-MM-dd HH:mm:ss.SSSxxx';
  if (timestamp.indexOf('T') !== -1) {
    strFormat = "yyyy-MM-dd'T'HH:mm:ss.SSSxxx";
  }
  if (timestamp.indexOf('Z') === -1) {
    date = parse(`${timestamp}.000Z`, strFormat, new Date());
  } else {
    date = parse(timestamp, strFormat, new Date());
  }
  if (format(date, 'yyyy') === format(new Date(), 'yyyy')) {
    return format(date, 'EEEE, MMMM dd');
  }
  return format(date, 'EEEE, MMMM dd, yyyy');
};

export const formatDelivery = (timestamp) => {
  // We're only interested in the day, not the time
  const date = parse(timestamp.split('T')[0], 'yyyy-MM-dd', new Date());
  // FIXME This should show "tomorrow" and similar where possible
  if (format(date, 'yyyy') === format(new Date(), 'yyyy')) {
    return format(date, 'EEEE, MMMM dd');
  }
  return format(date, 'EEEE, MMMM dd, yyyy');
};

export const dateFromDuration = (duration) => {
  const mins = Math.floor((duration / (1000 * 60)) % 60);
  const hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
  return new Date().setHours(hours, mins, 0, 0);
};

export const formatDuration = (duration) => {
  if (typeof duration !== 'string') {
    const hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
    let mins = Math.floor((duration / (1000 * 60)) % 60);
    mins = (mins < 10) ? `0${mins}` : mins;

    if (hours > 0 || mins > 0) {
      let formattedHours = '';
      if (hours === 1) {
        formattedHours = '1 hr';
      } else if (hours > 1) {
        formattedHours = `${hours} hrs`;
      }

      let formattedMins = '';
      if (mins === 1) {
        formattedMins = '1 min';
      } else {
        formattedMins = `${mins} mins`;
      }

      if (hours > 0) {
        return ` for ${formattedHours} ${formattedMins}`;
      }
      return ` for ${formattedMins}`;
    }
  } else {
    const parts = duration.split(':');
    const formattedHours = parts[0];
    const formattedMins = parts[1];
    return ` for ${formattedHours} hr ${formattedMins} min`;
  }
  return '';
};

// FIXME Update this file to use this function where needed
export const dateFromTimestamp = (timestamp) => {
  let strFormat = 'yyyy-MM-dd HH:mm:ss.SSSxxx';
  if (timestamp.indexOf('T') !== -1) {
    strFormat = "yyyy-MM-dd'T'HH:mm:ss.SSSxxx";
  }
  if (timestamp.indexOf('Z') === -1) {
    return parse(`${timestamp}.000Z`, strFormat, new Date());
  }
  return parse(timestamp, strFormat, new Date());
};

const shortMonths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
export const monthFromTimestamp = (timestamp) => {
  const date = dateFromTimestamp(timestamp);
  return shortMonths[date.getMonth()];
};

export const dayFromTimestamp = (timestamp) => {
  const date = dateFromTimestamp(timestamp);
  return date.getDate();
};

export const timeFromTimestamp = (timestamp) => {
  const date = dateFromTimestamp(timestamp);
  let hours = date.getHours();
  let minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'pm' : 'am';
  hours %= 12;
  hours = hours || 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  return `${hours}:${minutes} ${ampm}`;
};

/**
 * Calculates the number of days between two timestamps
 * @param timestamp1 The more recent date value
 * @param timestamp2 The earlier date value
 */
export const daysBetweenTimestamps = (timestamp1, timestamp2) => {
  if (!timestamp1 || !timestamp2) {
    return null;
  }
  const date1 = dateFromTimestamp(timestamp1);
  const date2 = dateFromTimestamp(timestamp2);
  const diff = differenceInMilliseconds(date1, date2);
  return Math.ceil(diff / oneDay);
};

export const toWordCase = (string) => string.trim().split(' ').map((word) => word[0].toUpperCase() + word.substring(1)).join(' ');
