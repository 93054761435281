import { useEffect, useState } from 'react';
import axios from 'axios';
import { apiKeys } from '../../settings';

const localCoordinates = window.localStorage.getItem('boxpressd_coordinates') ? JSON.parse(window.localStorage.getItem('boxpressd_coordinates')) : {};

export const useLocation = () => {
  const [coordinates, setCoordinates] = useState(localCoordinates);

  const getCoordinatesFromIpStack = () => {
    // INFO This is required to override the Authorization header (IPStack won't work with it)
    const ipstack = axios.create();
    ipstack.defaults.headers.common = {};
    ipstack.get('https://api.ipstack.com/check', {
      params: {
        access_key: apiKeys.ipstack,
        output: 'json',
      },
    }).then((response) => {
      console.log('IPStack response:');
      console.log(response);
      // logUserLocation(response.data.latitude, response.data.longitude);
      window.localStorage.setItem('boxpressd_coordinates', JSON.stringify(response.data));
      setCoordinates({
        latitude: response.data.latitude,
        longitude: response.data.longitude,
      });
    }).catch((err) => {
      console.log(err);
    });
  };

  useEffect(() => {
    try {
      window.navigator.permissions.query({ name: 'geolocation' }).then((result) => {
        if (result.state !== 'granted') {
          // Get the best estimate of their location until we can get the fine coordinates
          getCoordinatesFromIpStack();
        }
        // This will prompt for permission if not already granted
        window.navigator.geolocation && window.navigator.geolocation.getCurrentPosition((position) => {
          const coords = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          };
          window.localStorage.setItem('boxpressd_coordinates', JSON.stringify(coords));
          setCoordinates(coords);
        }, (err) => {
          console.error(err);
          getCoordinatesFromIpStack(); // Fallback
        });
      });
    } catch (err) {
      console.error(err);
      getCoordinatesFromIpStack(); // Fallback
    }
  }, []);

  return coordinates;
};
