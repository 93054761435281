import axios from 'axios';
import { config } from '../settings';

// TODO Rename this file to mediaUtils or similar

export const mediaInstance = axios.create();
mediaInstance.defaults.headers.common = {
  'Content-Type': 'multipart/form-data',
  Accept: 'text/html',
};

export const toBase64 = (file) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = (error) => reject(error);
});

/**
 * Handler to ensure cropped images are used in place of original images, but do not overwrite them.
 * @param originalImages The original, un-cropped images.
 * @param croppedImages The cropped images, based on the original images.
 * @return uploadedImages The images to be attached to the post (smoke session, checkin, etc). If not cropped, these will be the original images.
 */
// FIXME Better name for this function?
export const imageHolder = (originalImages, croppedImages) => {
  const uploadedImages = [];
  const thumbnailImages = [];
  return {
    originalImages,
    thumbnailImages,
    croppedImages,
    uploadedImages,
  };
};

export const uploadToS3 = (file, folder, filename, uuid, callback) => new Promise((resolve, reject) => {
  axios.post(`${config.mediaEndPoint}/sign`, {}, {
    params: {
      uuid,
      filename,
      filetype: file.type,
      type: folder,
    },
  }).then((result) => {
    console.log('Signed response:');
    console.log(JSON.stringify(result.data.signed_url));
    const options = {
      method: 'PUT',
      body: file,
    };

    if (typeof callback === 'function') {
      callback(result);
    }

    console.log('Direct upload in progress...');
    // INFO This wasn't working consistently with Axios, so switched to fetch
    fetch(result.data.signed_url, options).then((response) => {
      console.log('S3 success');
      if (!response.ok) {
        reject(response.statusText);
      }
      resolve(result);
    }).catch((err) => {
      console.log('S3 failure:');
      console.log(err);
      reject(err);
    });

    // console.log('Direct upload in progress...');
    // axios.put(result.data.signed_url, file).then((res) => {
    //   console.log(JSON.stringify(res.data));
    // }).catch((err) => {
    //   console.log(JSON.stringify(err));
    // });
  }).catch((err) => {
    console.log('Failed');
    reject(err);
  });
});

// FIXME Make another account for dev on cloudimage?
export const Resize = {
  // TODO https://docs.cloudimage.io/go/cloudimage-documentation-v7/en/image-resizing
  //  `func=crop` => cover, `func=bound` => contain, can also contain with blurred background with `func=fit&bg_img_fit=1&bg_opacity=0.75`
  //  https://docs.cloudimage.io/go/cloudimage-documentation-v7/en/image-resizing/
  thumbnail: (imageUrl, { cropType = 'crop', additionalParams = '' } = {}) => `https://ctjbxjajja.cloudimg.io/${imageUrl}?w=200&h=200&func=${cropType}&force_format=webp${additionalParams || ''}`,
  size: (imageUrl, { width = 500, height = 500, cropType = 'crop', additionalParams = '' } = {}) => `https://ctjbxjajja.cloudimg.io/${imageUrl}?w=${width}&h=${height}&func=${cropType}&force_format=webp${additionalParams || ''}`,
  fromCDN: (originalUrl, prefix, { width = 500, height = 500 } = {}) => originalUrl.replace(prefix, `${width}x${height}${prefix}`),
};
