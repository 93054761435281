import { Store } from 'pullstate';
import { Transducer } from '../utils/transducer';

// FIXME Update this file to TypeScript or use flow - this would avoid the need for all the info comments
export const UIStore = new Store({
  showMenu: false,
});

export const AppStore = new Store({
  sessionStarted: false,
  isNative: Transducer.getPreference('boxpressd_native_wrapper') === 'true' || Transducer.getPreference('boxpressd_native_wrapper') === true,
  platform: Transducer.getPreference('boxpressd_native_platform'), // || 'web'?
});

export const SettingsStore = new Store({
  darkMode: Transducer.getPreference('dark_mode') === 'true',
  theme: Transducer.getPreference('theme') || 'System', // 'System', 'Light', or 'Dark
  showCreateHumidorCard: Transducer.getPreference('show_create_humidor_card') === 'true',
  showPushRequestPrompt: false,
  disablePushRequestPrompt: Transducer.getPreference('disable_push_request_prompt') === 'true',
  showAgeAcknowledgement: Transducer.getPreference('show_age_acknowledgement') !== 'false',
  lastErrorId: null,
  // TODO User-specific settings stored to db like notifications, etc? Other settings from the "Settings" component?
});

export const UserStore = new Store({
  authState: null, // null, 'logged_in' or 'logged_out'
  user: Transducer.getPreference('user'), // INFO This is the same as auth.user
  firebaseUser: null,
  token: null,
  friends: [],
  outgoingRequests: [],
  incomingRequests: [],
  recentCigars: [],
  recommendedCigars: [],
  stats: {},
  smokeSessions: [],
  checkins: [],
  productReviews: [],
  friendRequestCount: 0,
  defaultShippingAddress: Transducer.getPreference('default_shipping_address') || {},
  defaultBillingAddress: Transducer.getPreference('default_billing_address') || {},
  autopost_facebook: Transducer.getPreference('autopost_facebook') === 'true',
  autopost_instagram: Transducer.getPreference('autopost_instagram') === 'true',
  autopost_twitter: Transducer.getPreference('autopost_twitter') === 'true',
  autopost_untappd: Transducer.getPreference('autopost_untappd') === 'true',
  autopost_foursquare: Transducer.getPreference('autopost_foursquare') === 'true',
});

export const SearchStore = new Store({
  cigars: null,
  cigarsLoading: false,
  venues: null,
  venuesLoading: false,
  brands: null,
  brandsLoading: false,
  products: null,
  productsLoading: false,
  users: null,
  usersLoading: false,
  groups: null,
  groupsLoading: false,
  articles: null,
  articlesLoading: false,
});

export const FeedStore = new Store({
  feed: Transducer.getPreference('boxpressd-main-feed') || [],
  loading: false,
  hasMore: true,
  error: null,
  currentPage: 0,
  hasNewPosts: false,
  // TODO Store likes here? Where are feed likes currently coming from?
  liked: [],
  // FIXME Should we do a default feed and a user-specific, or both the same? Only issue with both being the same is if a user logs out - it would need to reset
  //  Edit: I think that's fine - reset it. Don't store 2
});

export const CigarStore = new Store({
  sessions: {
    loading: false,
    liked: [], // FIXME LocalStorage?
    users: {
      loading: false,
    },
    // INFO Arrays of sessions based on cigar.id
  },
  saved: [ // FIXME LocalStorage?
    // INFO Array of IDs for saved cigars
  ],
  favorites: [
    // INFO Array of IDs for favorite cigars
  ],
  following: [
    // INFO Array of IDs for followed cigars
  ],
  explorer: [],
  topRated: [],
  brands: {
    following: [
      // INFO Array of IDs for followed cigar brands
    ],
  },
});

export const HumidorStore = new Store({
  humidors: {
    activeHumidor: Transducer.getPreference('activeHumidor'),
    // INFO Humidor should be based on the user ID
  },
});

export const VenueStore = new Store({
  nearby: [], // TODO Log recent search locations too, or just the latest set?
  checkins: {
    liked: [], // FIXME LocalStorage?
    // INFO Checkins should be based on the venue ID
  },
  sessions: {
    // INFO Sessions should be based on the venue ID
  },
  saved: [ // FIXME LocalStorage?
    // INFO Array of IDs for saved venues
  ],
  following: [
    // INFO Array of IDs for followed venues
  ],
  topRated: [],
});

export const ShopStore = new Store({
  cart: Transducer.getPreference('cart') || {
    items: [],
    billing_address: Transducer.getPreference('default_billing_address') || {},
    shipping_address: Transducer.getPreference('default_shipping_address') || {},
  },
  orders: [],
  products: {
    reviews: {
      liked: [], // FIXME LocalStorage?
      // INFO Reviews should be based on the product ID
    },
    saved: [ // FIXME LocalStorage?
      // INFO Array of IDs for saved products
    ],
    following: [
      // INFO Array of IDs for followed products
    ],
    topRated: [],
    listings: [],
    loadingListings: true,
    hasMoreListings: true,
  },
  payment_profile: Transducer.getPreference('payment_profile'),
  modified: false,
});

export const ArticleStore = new Store({
  articles: [], // ? Do we get all or just based on category?
  categories: {
    // INFO Category posts should be based on the category ID
  },
  liked: [
    // INFO Array of IDs for liked articles
  ],
  saved: [
    // INFO Array of IDs for saved articles
  ],
  following: [
    // INFO Array of IDs for followed articles
  ],
  comments: {
    // INFO Array of comments based on the article ID
  },
});

export const GroupStore = new Store({
  posts: {
    liked: [],
    // INFO Group posts should be based on the group ID
  },
  saved: [
    // INFO Array of IDs for saved groups
  ],
  following: [
    // INFO Array of IDs for followed groups
  ],
  notificationCount: 0,
});

export const BusinessStore = new Store({
  posts: {
    liked: [],
    // INFO Business posts should be based on the business ID
  },
  events: {
    liked: [],
    // INFO Business posts should be based on the business ID
  },
  blogs: {
    articles: {
      liked: [],
      // INFO Blog articles should be based on the business ID
    },
  },
  podcasts: {
    episodes: {
      liked: [],
      // INFO Podcast episodes should be based on the business ID
    },
  },
});

export const LoungeStore = new Store({
  // TODO What here? Chat messages and recent lounges? This should be able to lead them over to the lounge front end
  notificationCount: 0,
});

export const MediaStore = new Store({
  mediaMap: {
    // INFO These are external URLs mapped to local media files to avoid network calls when possible
  },
});

let alertStatus;
try {
  alertStatus = Transducer.getPreference('alert-status') || {};
} catch (e) {
  alertStatus = {};
}
export const NotificationsStore = new Store({
  notifications: Array.isArray(alertStatus.notifications) ? alertStatus.notifications : [],
  notificationCount: alertStatus.notificationCount || 0,
});
