import React from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory as createHistory } from 'history';

export const history = createHistory();
function HistoryBrowserRouter(props) {
  return <Router history={history} children={props.children} />;
}

export default HistoryBrowserRouter;
