export async function initializeAnalytics() {
  (function () {
    // FIXME Only for env = prod
    const env = process.env.NODE_ENV || 'development';
    if (env === 'production') {
      const e = (window.analytics = window.rudderanalytics = window.rudderanalytics || []);
      (e.methods = [
        'load',
        'page',
        'track',
        'identify',
        'alias',
        'group',
        'ready',
        'reset',
        'getAnonymousId',
        'setAnonymousId',
        'getUserId',
        'getUserTraits',
        'getGroupId',
        'getGroupTraits',
        'startSession',
        'endSession',
      ]),
      (e.factory = function (t) {
        return function () {
          e.push([t].concat(Array.prototype.slice.call(arguments)));
        };
      });
      for (let t = 0; t < e.methods.length; t++) {
        const r = e.methods[t];
        e[r] = e.factory(r);
      }
      (e.loadJS = function (e, t) {
        const r = document.createElement('script');
        (r.type = 'text/javascript'),
        (r.async = !0),
        (r.src = 'https://cdn.rudderlabs.com/v1.1/rudder-analytics.min.js');
        const a = document.getElementsByTagName('script')[0];
        a.parentNode.insertBefore(r, a);
      }),
      e.loadJS(),
      e.load('2Rsr6QikQohZB32tGkTK5kpUIPW', 'https://boxpressdkrg.dataplane.rudderstack.com'),
      e.page();
    }
  }());
}
